'use strict';


angular.module('kljDigitalLibraryApp')

.controller('CheckoutCtrl', ["$rootScope", "$scope", "$state", "Auth",
    "CheckoutService", "$cookies", "ngToast",
    function($rootScope, $scope, $state, Auth,
        CheckoutService, $cookies, ngToast) {


        //Create payment object

        function createPaymentFormData(data) {

            var form = document.createElement("form"),
                node = document.createElement("input");


            form.action = data.payment_url;
            form.method = "POST";

            node.name = "key";
            node.value = data.key;
            form.appendChild(node.cloneNode());

            node.name = "hash";
            node.value = data.hash;
            form.appendChild(node.cloneNode());

            node.name = "txnid";
            node.value = data.txnid;
            form.appendChild(node.cloneNode());

            node.name = "amount";
            node.value = data.amount;
            form.appendChild(node.cloneNode());

            node.name = "firstname";
            node.value = data.firstname;
            form.appendChild(node.cloneNode());

            node.name = "email";
            node.value = data.email;
            form.appendChild(node.cloneNode());

            node.name = "phone";
            node.value = data.phone;
            form.appendChild(node.cloneNode());

            node.name = "productinfo";
            node.value = data.productinfo;
            form.appendChild(node.cloneNode());

            node.name = "surl";
            node.value = data.surl;
            node.size = '64';
            form.appendChild(node.cloneNode());

            node.name = "furl";
            node.value = data.furl;
            node.size = '64';
            form.appendChild(node.cloneNode());

            node.name = "service_provider";
            node.value = data.service_provider;
            node.size = '64';
            form.appendChild(node.cloneNode());

            node.name = "udf1";
            node.value = data.udf1;
            form.appendChild(node.cloneNode());

            node.name = "udf2";
            node.value = data.udf2;
            form.appendChild(node.cloneNode());

            form.style.display = "none";
            document.body.appendChild(form);

            return form;

        }




        //Make payment
        $scope.makePayment = function(plan, couponCode, guest) {
           localStorage.setItem("couponCodeSubscription", couponCode?couponCode:'');
            CheckoutService.getPaymentDetails(plan._id, couponCode, guest)

            .then(function(data) {
                console.log("da", data);
                if (plan.plan_type === 'Trial') {
                    if ($state.current.name === 'main.subscription-details') {
                        $state.go("index.home");
                    } else {
                        $scope.$emit('updateCategoryList', true);
                        $scope.message = data;
                        $scope.success = true;
                    }
                } else {
                    let form = createPaymentFormData(data);
                    form.submit();
                }
            })

            .catch(function(error) {
                //Event Emiters: communication between controllers
                $scope.$emit('paymentError', error);
                $scope.message = error;
                $scope.success = false;
            })
        }

        //Event Emiters: communication between controllers
        $scope.$emit('showSearchBox', true);

    }
]);